import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Oshigoto
const ListJobs = () => import('@/views/v1/list')
const ViewJob = () => import('@/views/v1/view')
const CreateJob = () => import('@/views/v1/create')
const EditJob = () => import('@/views/v1/edit')
const Config = () => import('@/views/v1/config')
const ActivityLog = () => import('@/views/v1/activity_logs')
const Login = () => import('@/views/v1/login')
const Register = () => import('@/views/v1/register')

// Users
const EditUser = () => import('@/views/v1/edit_user')
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')


Vue.use(Router)

const requireAuth = (to, from, next) => {
  const status = true
  if(status) {
    next()
  }else{
    next({
      path: '/auth/login',
      query: { redirect: to.fullPath }
    })
  }
}


export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({y: 0}),
  routes: [
    {
      path: '/',
      redirect: '/list',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '/list',
          name: 'ListJobs',
          component: ListJobs,
          beforeEnter: requireAuth,
        },
        {
          path: '/view',
          name: 'ViewJob',
          component: ViewJob,
          beforeEnter: requireAuth,
          props: true
        },
        {
          path: '/create',
          name: 'CreateJob',
          component: CreateJob,
          beforeEnter: requireAuth,
          props: true
        },
        {
          path: '/edit',
          name: 'EditJob',
          component: EditJob,
          beforeEnter: requireAuth,
          props: true
        },
        {
          path: '/conf',
          name: 'Config',
          component: Config,
          beforeEnter: requireAuth,
          props: true
        },
        {
          path: '/logs',
          name: 'ActivityLog',
          component: ActivityLog,
          beforeEnter: requireAuth,
          props: true
        },
        {
          path: 'users',
          meta: {label: 'Users'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'edit',
              name: 'EditUser',
              component: EditUser,
              beforeEnter: requireAuth,
              props: true
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User,
              beforeEnter: requireAuth,
            }
          ]
        },
      ]
    },
    {
      path: '/auth',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
})

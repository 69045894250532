import 'core-js'
import Vue from 'vue'
import CoreuiVue from '@coreui/vue-pro/src'

import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import i18n from './i18n.js'

import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css'

Vue.use(CoreuiVue)
Vue.use(VueCroppie)
Vue.prototype.$log = console.log.bind(console)
Vue.mixin({
  computed: {
    user_id () {
      return store.state.user_id
    },
    family_id () {
      return store.state.family_id
    },
    nickname (){
      return store.state.nickname
    },
    image_path() {
      return store.state.image_path + "NvDUdxLS/" + store.state.user_id + "/0/" + "G1HG2aatuh.jpg"
    }
  },
})

new Vue({
  el: '#app',
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  },
})

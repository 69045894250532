import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  // sidebarMinimize: false,
  // asideShow: false,
  darkMode: true,
  showMemberList: true,
  token: '',
  user_id: 0,
  family_id: 0,
  family_code: 'NvDUdxLS',//これは里川家のコードなので動的にしてください。
  username: '',
  mail: '',
  nickname: '',
  first_name: '',
  last_name: '',
  is_admin: false,
  image_path: 'https://s3.ap-northeast-1.amazonaws.com/src.img/',
  your_family: [],
  job_items: [],
  status_options: [],
  job_categories: [],
  notifications: {
    total: 0,
    propose: 0,
    offer: 0,
    question: 0,
    over_date: 0
  }
}

const mutations = {
  get_job_items(state, items){
    state.job_items = items
  },
  add_job_items (state, items) {
    items.map(item => state.job_items.push(item))
  },
  splice_job_item (state, [index, item]){
    state.job_items.splice(index, 1, item)
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  }
}

export default new Vuex.Store({
  state,
  mutations,
  plugins: [createPersistedState()]
})
